import React, { Component } from 'react';

import qs from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import { FaShoppingCart, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';

import './styles.css';

import Header from '../../components/Header/index';

import ItemProduct from '../../components/ItemProduct/index';

import ImageModal from '../../components/ImageModal/index';

import CryptoJS from 'crypto-js/aes';

// import CryptoENC from 'crypto-js/enc-utf8';

import api from '../../services/api';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Creators as CartCreator } from '../../store/ducks/cart';

// import { toast } from 'react-toastify';

import { FaCheck } from 'react-icons/fa';

import Currency from '../../utils/currency';
import MaskedInput from 'react-text-mask';
import { GerarToken } from '../../hook/token';

const groups = [];
const products = [];
const additional = [];

/**
 * https://www.browserling.com/tools/aes-decrypt
 * https://www.browserling.com/tools/aes-encrypt
 * id 
 * type - query param 
 * description - parametro que passa o tipo do pedido, estabelecimento, e numero da mesa
 * length - 15
 * 
 * encrypted 
 *  U2FsdGVkX19DuslDNaJy73yusNQTIXnx0xY7Hdz3eOU=
 * 
 * 
 * ex: 5- 1 -4- 00010 -9- 0 -2- 1 -61- 7      
 *     514000109021618
 *  tipo pedido = 1 - (2 - 2)
 *  estabelecimento = 00010 (4 - 8)
 *  nr pedido = 0 (10 - 10) 1 (12 - 12) 7 (15-15)
 *  
 */

class Products extends Component {
   
  constructor(props) {
    super(props)

    const { id, token, nomeEmpresa } = qs.parse(this.props.location.search);

    let dataParams = null;

    if (token !== undefined) {
      var encrypt = CryptoJS.encrypt(token, "gerence@#");
      let decrypted = CryptoJS.decrypt(encrypt, "gerence@#");
    }

    if (nomeEmpresa !== undefined) {
      localStorage.setItem('@comer-aqui/nomeEmpresa', nomeEmpresa);
    }
    else {
      localStorage.setItem('@comer-aqui/nomeEmpresa', null);
    }

    if (id !== undefined) {
      localStorage.setItem('@comer-aqui/authorization', id);
      dataParams = this.getInfoOrder(id);

      localStorage.setItem('@comer-aqui/numberOrder', dataParams.numberOrder);
      localStorage.setItem('@comer-aqui/typeOrder', 'MESA') 
    } 
    else {
      localStorage.setItem('@comer-aqui/numberOrder', 0);
      localStorage.setItem('@comer-aqui/typeOrder', 'ENTREGA') 
    }

    this.state = {
      empresa: {},
      groups,
      filterGroups: groups,
      grupos: [],
      idGrupoFilter: "",
      dataParams,
      products,
      additional,
      modalVisible: false,
      urlImgModal: '',
      loading: true,
      modalSuccess: false
    }
  }

  handleShowModal = (image) => {
    if (image !== undefined) {
      this.handleSetImageModal(image);
    }

    this.setState({ modalVisible: !this.state.modalVisible }, () => { });
  }

  handleSetImageModal = (image) => {
    this.setState({ urlImgModal: image });
  }

  getInfoOrder = (hash) => {
    const typeOrder = parseInt(hash.substring(1, 2)); //Não está sendo usado
    const establishment = parseInt(hash.substring(3, 8));
    const numberOrder = parseInt(hash.substring(9, 10) + hash.substring(11, 12) + hash.substring(14, 15)); //Modelo novo
    //const numberOrder = parseInt(hash.substring(16, 19)); //Modelo antigo
    //localStorage.setItem('@comer-aqui/nomeEmpresa', 'gerence'); //Modelo antigo 


    var data = {
      typeOrder, //Não está sendo usado
      establishment,
      numberOrder
    }

    // console.log("Empresa: " + localStorage.getItem('@comer-aqui/nomeEmpresa') + " Informações: typeorder=" + data.typeOrder + ', establishment=' + data.establishment + ', numberOrder=' + data.numberOrder);
    return data;
  }

  buscarEmpresa = (producao, host, empresaUrlSubdominio) => {
    let nomeEmpresa = "";

    // Claudemir e Cesar - 22/11/2022
    // qrCode
    let qrCode = ""

    if (producao) {


      if (String(host).indexOf(".") > -1) {

        // § Quando tem subdominio pega o sub dominio como primeiro nome da empresa
        nomeEmpresa = empresaUrlSubdominio;
        // nomeEmpresa = "gerence";

      }
    } else {
      nomeEmpresa = "acaidecasa";
    }

    // console.log("1",nomeEmpresa)

    // Busca o nome da empresa depois da barra, exemplo:
    // comeraqui.com.br/gerence


    if ((nomeEmpresa == "") || (nomeEmpresa == null) || (nomeEmpresa == undefined)) {
      const url = host.split("/");
      nomeEmpresa = url[url.length - 1];


      // console.log('nomeEmpresa 111' + nomeEmpresa)

      // Claudemir e Cesar - 22/11/2022

      // se as 3 primeiras posições da empresa encontrada for "?id", então é um qrCode
      // nesse caso, vai pegar a empresa da barra anterior
      // exemplo de chamada de qrCode:
      // http://localhost:3000/gerence/?id=123456789102 - Ele pega o antepenúltimo e último

      qrCode = nomeEmpresa.substring(0, 3)


      //  console.log('qrCode ' + qrCode)

      if (qrCode == "?id") {
        nomeEmpresa = url[url.length - 2];
      }

      //   console.log('nomeEmpresa2 ' + nomeEmpresa)
    }

    // console.log("2",nomeEmpresa)        

    // Se o nomeEmpresa ainda continuar vazio, então vai verificar se possui uma barra no final da url
    if ((nomeEmpresa == "") || (nomeEmpresa == null) || (nomeEmpresa == undefined)) {
      let urlBarra = "";
      urlBarra = host.split("/");
      urlBarra = urlBarra[urlBarra.length - 2];


      // console.log('urlBarra ' + urlBarra)

      if (urlBarra != "" && urlBarra != "comeraqui.com.br") {
        nomeEmpresa = urlBarra;
      }
    }
    return nomeEmpresa;
  }

  async componentDidMount() {
    
    window.onbeforeunload = function () { return ""; }

    //let host = window.location.hostname;
    let host = window.location.href;

    // host = 'comeraqui.com.br/megalanche';
    // host = 'http://gerence.comeraqui.com.br/gerence/?id=444812332060051733';

    let empresaUrlSubdominio = "";

    host = String(host).replace("www.", "");
    host = String(host).replace("http://", "");
    host = String(host).replace("https://", "");
    host = String(host).replace("?nomeEmpresa=", "");

    let hostSeparado = host.split(".");

    // console.log("hostSeparado", hostSeparado);
    // console.log('host separador = ', hostSeparado[0])

    if (hostSeparado.length >= 4) {
      if (hostSeparado[0] != "novo") {
        empresaUrlSubdominio = hostSeparado[0];
      } else {
        hostSeparado = host.split("/");
        empresaUrlSubdominio = hostSeparado[1];
      }
    }
    // Alterar essa variavel para False para poder testar no celular colocando apenas o IP
    let producao = true;

    let empresaUrl = "";
    

    // Verificar se tem alguma empresa no local storage
    empresaUrl = localStorage.getItem('@comer-aqui/empresaUrl');

    // console.log('empresaUrl ' + empresaUrl)
    // console.log('empresaUrlSubdominio ' + empresaUrlSubdominio)

    // Busca a empresa na url ex: comeraqui.com.br/gerence ou no subdominio ex: gerence.comeraqui.com.br
    let empresaLogada = this.buscarEmpresa(producao, host, empresaUrlSubdominio);
    

    // console.log('empresaLogada 1 ' + empresaLogada)

    // Compara se a empresa atual da url é a mesma que esta salva no local storage pois o cliente pode ter trocado de loja
    if (empresaUrl != empresaLogada && empresaLogada != "") {
      empresaUrl = empresaLogada;
    }

    let user = localStorage.getItem("@user_data");

    let telefone = "";

    if (user) {
      user = JSON.parse(user);
      telefone = user.phoneNumber;
    }
    

    // console.log("1",empresaUrl)

    // Salva a empresa no local storage
    localStorage.setItem('@comer-aqui/empresaUrl', empresaUrl);

    // console.log("info = ", host   )

    // Cesar - 26/08/2022
    let tpPedido = ''
    
    
    if (localStorage.getItem('@comer-aqui/typeOrder') === 'MESA') {
      tpPedido = 1;
    } 
    else {
      tpPedido = 0;
    }

    try {
      empresaUrl = empresaUrl.split('&')[0];


      const response = await api.get('/produtos/', { params: { empresa: empresaUrl, telefone, tpPedido } });

      // volta aqui
      GerarToken(response.data.token)

      if (response !== null) {
        window.localStorage.setItem("dsChavepixel", response?.data?.empresa?.dsChavepixel)
      }

      const types = ["E", "G", "N"];

      let groups = [];
      let filtroGrupos = [];
      let products = [];

      let { grupos, produtos, adicionais, empresa, resposta } = response.data;

      if (resposta) {
        if (resposta.status == 404 || resposta.status == "404") {
          this.props.history.push({ pathname: '/home' });
          return;
        }
      }

      localStorage.setItem('@comer-aqui/empresa', JSON.stringify(empresa));
      localStorage.setItem('@comer-aqui/vltaxaEntrega', empresa.vlTaxaentrega);

      this.setState({
        typeOrder: empresa.tpUtilizaqrcode == 3 ? "COMANDA" : "MESA",
      });

      if (empresa.tpUtilizaqrcode == 3) {
        localStorage.setItem("@comer-aqui/tpUtilizaQRCode", 3);
      }

      if (empresa.tpProdutounico) {
        localStorage.setItem("@comer-aqui/tpProdutounico", 1);
      }

      if (empresa.idImpressora) {
        localStorage.setItem("@comer-aqui/idImpressora", empresa?.idImpressora);
      }

      for (let produto of produtos) {
        if (types.includes(produto.tpProduto)) {
          products.push(produto);
        }
      }

      for (let grupo of grupos) {
        let isset = products.filter(product => product.idGrupo == grupo.idGrupo)
        if (isset.length > 0) {
          groups.push(grupo);
          filtroGrupos.push(grupo);
        }
      }

      filtroGrupos.push({ idGrupo: 0, dsGrupo: "TODOS" });

      filtroGrupos.sort((a, b) => a.idGrupo - b.idGrupo);

      let inExistePromocao = false;
      for (let produto of products) {

        if (produto.vlPromocao > 0) {
          inExistePromocao = true;
        }

        if ((produto.vlPrecoentrega == 0) || (produto.vlPrecoentrega == null)) { // Gabriel - 15/06/2022 - Atualizado para o caso do Hamburgão
          produto.vlPrecoentrega = produto.vlPreco // Vide alterações desse dia na ScreenProduct
        }
      }

      // erro aqui filtra categorias

      this.setState({
        toggledPromocao: true,
        empresa,
        all_products: produtos,
        products,
        groups,
        filterGroups: groups,
        grupos: filtroGrupos.sort((a, b) => a.tpOrdem - b.tpOrdem),
        additional: adicionais,
        loading: false,
        inExistePromocao
      }, () => { });

    } catch (error) {
      // console.log(error);


      this.props.history.push({ pathname: '/home', });
      return;
    }

    // Cesar - 11/10/22 - Removi o toast para adicionar modal pedido finalizado
    let msgToast = localStorage.getItem('@comer-aqui/msgToast');
    if (msgToast != null) {

      this.setState({
        modalSuccess: true
      })

      setTimeout(() => {
        this.setState({
          modalSuccess: false
        })
      }, 3000)

      // alert('Pedido realizado com sucesso')
      // toast.success(msgToast);                
      localStorage.removeItem('@comer-aqui/msgToast');
    }
  }

  handleCart = () => {
    let { empresa } = this.state;

    if (!empresa) {
      return;
    }

    if (empresa.situacao == 0) {
      return;
    }

    if (this.props.cart.id == null) {
      const numberOrder = localStorage.getItem('@comer-aqui/numberOrder');
      const typeOrder = localStorage.getItem('@comer-aqui/typeOrder');
      this.props.newCart(typeOrder, numberOrder);
    }

    this.props.history.push({ pathname: '/cart', });
  }

  slide = (direction) => {
    let container = document.getElementById('container-buttons-filter');
    let scrollCompleted = 0;

    let slideVar = setInterval(function () {
      if (direction == 'left') {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }

      scrollCompleted += 10;

      if (scrollCompleted >= 100) {
        window.clearInterval(slideVar);
      }
    }, 50);
  }

  render() {

    let _toggleGroup = (group) => {
      let groupsEdit = [...this.state.groups];

      let index = groupsEdit.indexOf(group);

      groupsEdit[index].toggled = !groupsEdit[index].toggled;

      this.setState({ groups: groupsEdit });
    }

    const _toggleGroupPromocao = () => {
      this.setState({ toggledPromocao: !this.state.toggledPromocao });
    }

    const {
      empresa,
      idGrupoFilter,
      filterGroups,
      grupos,
      products,
      all_products,
      loading,
      dataParams,
      modalVisible,
      urlImgModal,
      modalSuccess
    } = this.state;

    let qtInPromocaoGroup = 0;
    for (let produto of products) {

      if (produto.vlPromocao > 0 && (produto.idGrupo == idGrupoFilter || idGrupoFilter == 0)) {
        qtInPromocaoGroup++;
      }
    }

    const filterGrupo = (grupo) => {
      let { groups, idGrupoFilter } = this.state

      if (grupo === 0) {
        this.setState({ filterGroups: groups, idGrupoFilter: "" });

      } else {
        if (grupo === idGrupoFilter) {
          this.setState({ filterGroups: groups, idGrupoFilter: "" });

        } else {
          let filterGroups = groups.filter(
            ({ idGrupo }) => idGrupo === grupo
          );
          filterGroups[0].toggled = true
          this.setState({ filterGroups, idGrupoFilter: grupo });
        }
      }
    };


    // adicionado por Cesar para ignorar avisos de tarefas para serem corrigidas
    // console.clear()

    return (
      <div className="container p-0">
        {
          loading ? (
            <div className="content-loader">
              <div className="loader"></div>
            </div>
          ) :
            (
              <div>
                <ImageModal show={modalVisible} url={urlImgModal} onClickClose={() => this.handleShowModal()} />
                <Header />
                {/* {empresa.situacao === 0 && <p className="texto-empresa-fechada">Empresa está fechada no momento, volte mais tarde!</p>} */}

                {/* Cesar - 20/07/2022 */}
                {dataParams ? (
                  <>

                    {
                      empresa.tpSituacaoqrcode ?
                        <></>


                        : (
                          <>{empresa.tpSituacaoqrcode === 0 && <p className="texto-empresa-fechada">{empresa.fantasia} está fechada no momento, volte mais tarde!</p>}</>
                        )}
                  </>
                ) : (
                  <>
                    {empresa.situacao === 0 && <p className="texto-empresa-fechada">{empresa.fantasia} está fechada no momento, volte mais tarde!</p>}
                  </>
                )}

                {dataParams && (
                  <h3 className="text-center mt-2">
                    {this.state.typeOrder} {dataParams.numberOrder}
                  </h3>
                )}

                {/* Cesar - 11/10/22 */}
                {modalSuccess ? (
                  <>
                    <div
                      style={{
                        alignItems: 'center',
                        background: 'rgba(0, 0, 0, .75)', // RGBA caso queira usar opacidade
                        color: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '520px',
                        overflow: 'hidden',
                        position: 'fixed',
                        textAlign: 'center',
                        textShadow: '0 0.05rem 0.1rem rgb(0 0 0 / 50%)',
                        top: 0,
                        bottom: 0,
                        transition: 'all ease-in-out 1500ms',
                        zIndex: 1040
                      }}
                      onClick={() => this.setState({ modalSuccess: false })}
                    >
                      <>
                        <div style={{ position: 'relative', width: 'calc(100% - 30px)', top: '-25%', }}>

                          <div style={{ position: 'absolute', left: 0, width: '100%' }}>
                            <div style={{ position: 'relative' }}>

                              <div style={{ position: 'absolute', top: 16, right: 16 }}>
                                <FaTimes size={24} />
                              </div>

                              <div
                                className='d-flex flex-column rounded bg-success p-5 shadow'
                              >
                                <FaCheck className='mb-3' size={72} style={{ margin: 'auto' }} />
                                <h4>Pedido feito com sucesso!</h4>
                              </div>
                            </div>
                          </div>

                        </div>
                      </>
                    </div>
                  </>
                ) : null}

                <div className="container mt-1 mb-1" id="content-buttons-filter">
                  <div className="row">
                    <div className="col-md-1 col-xl-1 col-sm-1 col-1 icons-filter-action">
                      <FaArrowLeft className="icon-filter-action" onClick={() => this.slide('left')} />
                    </div>
                    <div className="col-md-10 col-xl-10 col-sm-10 col-10">
                      <div id="container-buttons-filter">
                        {
                          grupos.map(grupo => (
                            <div key={`${grupo.idGrupo}-${grupo.dsGrupo}`}>
                              <button onClick={() => filterGrupo(grupo.idGrupo)} className={`ml-2 mr-2 button-filter ${grupo.idGrupo == idGrupoFilter ? 'button-filter-active' : ''}`} type="button">
                                {grupo.dsGrupo}
                              </button>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className="col-md-1 col-xl-1 col-sm-1 col-1 icons-filter-action">
                      <FaArrowRight className="icon-filter-action" onClick={() => this.slide('right')} />
                    </div>
                  </div>
                </div>
                {
                  this.state.inExistePromocao && qtInPromocaoGroup > 0 &&
                  <div>
                    {
                      <section id="product-list">
                        <h5 key={0} className="title-group" onClick={() => (_toggleGroupPromocao(this.state.toggledPromocao))}>
                          {"Promoção"}
                          {this.state.toggledPromocao ? <FontAwesomeIcon style={{ marginLeft: 8 }} icon={faAngleUp} /> : <FontAwesomeIcon style={{ marginLeft: 8 }} icon={faAngleDown} />}
                        </h5>
                        {this.state.toggledPromocao &&

                          filterGroups.map(group => (products.filter(productP => (productP.vlPromocao > 0) && (productP.idGrupo == group.idGrupo)).map((product) => (
                            <ItemProduct
                              grupo="Promoção"
                              grupo_id={0}
                              key={product.idProduto}
                              id={product.idProduto}
                              url={product.dsImagem}
                              alt={product.dsProduto}
                              name={product.dsProduto}
                              description={product.dsIngredientes}
                              price={product.vlPreco}
                              promotion_price={product.vlPromocao}
                              type={product.tpProduto}
                              remove={product.remove}
                              add={product.add}
                              complement={product.inComplemento}
                              history={this.props.history}
                              showModal={this.handleShowModal}
                              ingredients={product.ingredientes}
                              additional={this.state.additional}
                              attrs={product}
                              all_products={all_products}
                            />
                          ))))
                        }

                      </section>
                    }
                  </div>
                }
                <div className="mb-5">
                  {
                    filterGroups.map(group => (
                      <section id="product-list" key={group.idGrupo}>
                        <h5 key={group.idGrupo} className="title-group" onClick={() => (_toggleGroup(group))}>
                          {String(group.dsGrupo)}
                          {group.toggled ? <FontAwesomeIcon style={{ marginLeft: 8 }} icon={faAngleUp} /> : <FontAwesomeIcon style={{ marginLeft: 8 }} icon={faAngleDown} />}
                        </h5>
                        {
                          group.toggled && products.filter(product => (product.idGrupo == group.idGrupo && !(this.state.idGrupoFilter != 0 && product.vlPromocao > 0))).map((product, key) => (
                            <ItemProduct
                              grupo={group}
                              grupo_id={product.idGrupo}
                              key={product.idProduto}
                              index={key}
                              id={product.idProduto}
                              url={product.dsImagem}
                              alt={product.dsProduto}
                              name={product.dsProduto}
                              description={product.dsIngredientes}
                              vlPreco={product.vlPreco}
                              price={product.vlPrecoentrega}
                              promotion_price={product.vlPromocao}
                              type={product.tpProduto}
                              remove={product.remove}
                              add={product.add}
                              complement={product.inComplemento}
                              history={this.props.history}
                              showModal={this.handleShowModal}
                              ingredients={product.ingredientes}
                              additional={this.state.additional}
                              attrs={product}
                              all_products={all_products}
                              dataParams={dataParams}
                              empresa={empresa}
                            />
                          ))
                        }
                      </section>
                    ))
                  }
                </div>
                <div className="fixed-bottom">
                  <div className='container'>
                    <div className="row">
                      <div className="col-12 p-0">

                        {/* Cesar - 20/07/2022 */}
                        {dataParams ? (
                          <>
                            {empresa.tpSituacaoqrcode === 0 ?
                              <>
                                {this.props.cart.items.length > 0 ? (
                                  <button className={`btn btn-success btn-block p-2`} onClick={this.handleCart}>
                                    Carrinho <FaShoppingCart /> {Currency.formatterValue.format(parseFloat(this.props.cart.totalprice))}
                                  </button>
                                ) : (
                                  <button className={`btn btn-danger btn-block p-2`} disabled={true}>
                                    Nenhum produto no carrinho <FaShoppingCart />
                                  </button>
                                )}
                              </>
                              : (
                                <></>
                              )}
                          </>
                        ) : (
                          <>
                            {empresa.situacao === 0 ? (
                              <p className="texto-empresa-fechada">{empresa.fantasia} está fechada no momento, volte mais tarde!</p>
                            ) : (
                              <>
                                {this.props.cart.items.length > 0 ? (
                                  <button className={`btn btn-success btn-block p-2 ${empresa && empresa.situacao == 0 ? 'empresa-fechada' : ''}`} onClick={this.handleCart}>
                                    Carrinho <FaShoppingCart /> {Currency.formatterValue.format(parseFloat(this.props.cart.totalprice) + parseFloat(empresa.vlTaxaentrega))}
                                  </button>
                                ) : (
                                  <button className={`btn btn-danger btn-block p-2 ${empresa && empresa.situacao == 0 ? 'empresa-fechada' : ''}`} disabled={true}>
                                    Nenhum produto no carrinho <FaShoppingCart />
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {/* <button className={`btn btn-success btn-block p-2 ${empresa && empresa.situacao !== 0 ? 'empresa-fechada' : ''}`} onClick={this.handleCart}>
                                                    Carrinho <FaShoppingCart />
                                                </button> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            )
        }
      </div>

    );
  }
}

const mapStateToProps = state => ({ product: state.product, cart: state.cart });

const mapDispatchToProps = dispatch => bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Products);

// export default Products;
