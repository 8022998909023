// export const BASE_URL = "http://servidor.gerencesistemas.com.br:9000"
// export const BASE_URL = "https://api.gerencesistemas.com.br:9000"


// export const BASE_URL = "http://192.168.15.21:9000"

// baseUrl = "http://servidor.gerencesistemas.com.br:9000"
// baseUrl = "http://192.168.15.7:9000";

// export const BASE_URL = "http://192.168.15.6:9000";


// URL SERVIDOR



// export const BASE_URL = "http://apiatual.pedidosdozap.com.br"

// export const BASE_URL = "https://api.comeraqui.com.br"


export const BASE_URL = "https://api.pedidosdozap.com.br"
