import React, { Component } from "react";

import "./styles.css";

import { FaInfoCircle } from "react-icons/fa";

import MaisInfoModal from "../MaisInfoModal/index";
import { renderToString } from "react-dom/server";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresa: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    let empresa = localStorage.getItem("@comer-aqui/empresa");

    if (empresa) {
      this.setState({ empresa: JSON.parse(empresa) });
    }
  }

  handleShowModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible }, () => { });
  };

  render() {
    let { props } = this;

    let { empresa, modalVisible } = this.state;

    return (
      <header className="row" id="main-header">
        {empresa.logo && (
          <div className="logo col-md-2 col-xl-2 col-sm-2 col-2">
            <img
              src={`data:image/jpeg;base64,${empresa.logo}`}
              alt={empresa.fantasia}
            />
          </div>
        )}
        <div
          className={`empresa ${empresa.logo
              ? "col-md-10 col-xl-10 col-sm-10 col-10"
              : "col-md-12 col-xl-12 col-sm-12 col-12"
            }`}
        >
          <div className="dados-empresa">
            <div className="fantasia">{empresa.fantasia}</div>
            {/* <div className="col-md-12 col-xl-12 col-sm-12 col-12 pt-0 pb-0">
              Razão social: <strong>{empresa.razaoSocial}</strong>
            </div> */}
            <div>
              Endereço: <strong>{empresa.endereco}</strong>
            </div>
            <div>
              Bairro: <strong>{empresa.bairro}</strong>
            </div>
            <div>
              Nº: <strong>{empresa.nrLogradouro}</strong>
            </div>            
            <div>
              CEP: <strong>{empresa.cep}</strong>
            </div>
            <div>
              Telefone: <strong>{empresa.telefone}</strong>
            </div>
          </div>
        </div>

        {empresa.dsMensageminicial && (
          <div className="col-12 px-0 py-1 dsMensageminicial">
            <h6
              className="titulo mb-0 "
              dangerouslySetInnerHTML={{ __html: empresa.dsMensageminicial }}
            ></h6>
          </div>
        )}
      </header>
    );
  }
}

export default Header;