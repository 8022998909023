import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import './styles.css';

import Header from '../../components/Header';

import { Creators as CartCreator } from '../../store/ducks/cart';

import { FaArrowLeft, FaPlus, FaMinus } from 'react-icons/fa';

import iconComerAqui from '../../assets/image/icon.png'

let idSeq = 1;

class ScreenProduct extends Component {

    addItem = () => {
        return {
            id: `PIZZA${Math.floor(Math.random() * 256)}${idSeq++}`,
            ordered: this.props.cart.id,
            product: {
                id: this.state.product.idProduto,
                name: this.state.product.dsProduto,
                quantity: this.state.quantity,
                value: this.state.total,
                total: this.state.total * this.state.quantity,
                sabores: this.state.items,
                quantity_flavors: this.state.quantity_flavors,
            },
            status: 1,
            remove: undefined,
            add: undefined,
            observation: this.state.observation
        };
    }

    handleSubmit = e => {
        e.preventDefault();

        if (this.props.cart.id == null) {
            const numberOrder = localStorage.getItem('@comer-aqui/numberOrder');
            const typeOrder = localStorage.getItem('@comer-aqui/typeOrder');
            this.props.newCart(typeOrder, numberOrder);
        }

        const itemAdd = this.addItem();

        const { borda } = this.props.location;
        itemAdd.borda = borda;

        if (borda) {
            itemAdd.product.value += borda.vlPreco;
            itemAdd.product.total = itemAdd.product.value * itemAdd.product.quantity;
        }

        let items = [];

        items.push(itemAdd);

        this.props.addItem(itemAdd);

        this.props.history.push({
            pathname: '/cart',
            data: items
        });
    }

    handleInputChange = e => {
        this.setState({ observation: e.target.value })
    }

    handleClickQuantity = type => {
        var quantity = this.state.quantity;

        if (type === 1) {
            quantity += 1;
        } else {
            if (quantity !== 1 && quantity > 1) {
                quantity -= 1;
            }
        }

        this.setState({ quantity });
    }

    constructor(props) {
        super(props);

        window.scrollTo(0, 0);

        let total = 0;
        let maiorValor = 0;
        let { items } = props.location.pizza

        for (let item of items) {
            total = parseFloat(total) + parseFloat(item.total);
            if (item.value > maiorValor) {
                maiorValor = item.value;
            }
        }

        let existeEmpresa = localStorage.getItem("@comer-aqui/empresa");

        if (!existeEmpresa) {
            return;
        }

        let empresa = JSON.parse(existeEmpresa);

        // tpValordivisaoitem
        // 0 = Soma todos os valores
        // 1 = Pega o maior valor

        let tpValor = empresa.tpValordivisaoitem;

        if (tpValor == 1) {
            total = 0;
            for (let item of items) {
                total = parseFloat(total) + parseFloat(maiorValor * item.quantity);
                item.value = maiorValor * item.quantity;
            }
        }

        this.state = {
            product: props.location.product,
            itemsCart: [],
            observation: '',
            quantity: 1,
            quantity_flavors: props.location.quantity_flavors,
            items,
            total: total / props.location.quantity_flavors,
            errored: false,
            empresa
        }
    }

    handleBack = () => {
        // this.props.history.goBack();

        if (localStorage.getItem('@comer-aqui/typeOrder') == "ENTREGA") {
            //   this.props.history.push({ pathname: '/' })
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') )
        } else {
            // console.log("teste 56 = ", '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'))
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));
        }
    }

    onError() {
        if (!this.state.errored) {
            this.setState({ errored: true });
        }
    }

    render() {

        const { product } = this.props;
        const { borda } = this.props.location;

        const { items, quantity_flavors, errored } = this.state;

        return (
            <>
                <Header />
                <div id="container">
                    <div id="content-item">
                        <div className="container-img-pizza-quantity">
                            {errored || !product.product.url ?
                                // <FaCamera size="25" />
                                <img src={iconComerAqui} alt='Comer Aqui' className='img-fluid mb-1' style={{ opacity: .35, height: 72, objectFit: 'contain' }} />
                                :
                                <img src={product.product.url} className="img-pizza-quantity" alt={product.product.alt} onError={this.onError} />
                            }
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <h6>{product.product.name}</h6>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            {
                                items.map((item, index) => (
                                    <div className="col-md-12 col-xl-12 col-sm-12 col-12" key={`${item.id}-${index}`}>
                                        ({item.quantity}/{quantity_flavors})<span className='ml-1'>{item.name}</span>
                                    </div>
                                ))
                            }

                            {
                                borda &&
                                <div className="col-md-12 col-xl-12 col-sm-12 col-12">
                                    {borda.dsProduto}
                                </div>
                            }
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 text-center"><span className="title-quantity">Quantidade</span></div>
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                                <button id="decrease-quantity" onClick={(e) => this.handleClickQuantity(2)}>
                                    <FaMinus />
                                </button>
                                <h6 className="m-3">{this.state.quantity}</h6>
                                <button id="increase-quantity" onClick={(e) => this.handleClickQuantity(1)}>
                                    <FaPlus />
                                </button>
                            </div>
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12">
                                <h6>Observação?</h6>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder="Digite aqui sua observação..."
                                    value={this.state.observation}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="fixed-bottom">
                        <div className="row">
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                                <button className="btn btn-success btn-block p-3" onClick={this.handleSubmit}>
                                    Adicionar ao pedido <FaPlus />
                                </button>
                            </div>
                            <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0">
                                <button className="btn btn-dark btn-block btn-go-back" onClick={() => this.handleBack()}>
                                    <FaArrowLeft /> Voltar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({ product: state.product, cart: state.cart });

const mapDispatchToProps = dispatch => bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenProduct);
